<template>
  <form
    v-show="!dupEmail"
    @submit.prevent="handleOtpSubmission"
  >
    <AtomTextField
      type="text"
      label="Email"
      input-key="email"
      placeholder="Please enter your email"
      error-msg="Invalid email format."
      :valid="validEmail"
      @input="handleInput"
    />
    <AtomTextField
      type="text"
      label="First Name"
      input-key="givenName"
      placeholder="Enter your First Name"
      :valid="validFirstName"
      error-msg="Invalid characters in first name."
      @input="handleInput"
    />
    <AtomTextField
      type="text"
      label="Last Name"
      input-key="familyName"
      placeholder="Enter your Last Name"
      :valid="validLastName"
      error-msg="Invalid characters in last name."
      @input="handleInput"
    />
    <AtomDateField
      type="text"
      label="Date of Birth"
      input-key="dateOfBirth"
      placeholder="MM/DD/YYYY"
      :valid="validateOfBirth"
      error-msg="Invalid date of birth"
      @input="handleInput"
    />
    <div class="w-full text-right mb-3" />
    <AtomButton
      type="submit"
      :disabled="!isFormValid"
      label="Continue"
      :btn-class="'font-bold h-[31px] xl:h-10 w-full text-white font-bold text-m-btn xl:text-base py-3 px-2.5 flex items-center justify-center'"
    />
  </form>
</template>

<script setup lang="ts">
import { useUserStore } from "~/store/user";
import { storeToRefs } from "pinia";
import { useFormValidation } from "@/composables/useFormValidation";
import type { SignInOtpData } from "@/models";

const { validateEmail } = useFormValidation();
const userStore = useUserStore();
const { authValid, dupEmail } = storeToRefs(userStore);
const validEmail = ref(true);
const validFirstName = ref(true);
const validLastName = ref(true);
const validateOfBirth = ref(true);
const validSubmission: Ref<boolean> = ref(true);
const submission: Ref<SignInOtpData> = ref({
	email: "",
	givenName: "",
	familyName: "",
	dateOfBirth: "",
});

const isFormValid = computed(() => {
	return (
		validEmail.value &&
		validFirstName.value &&
		validLastName.value &&
		validateOfBirth.value &&
		submission.value.email !== "" &&
		submission.value.givenName !== "" &&
		submission.value.familyName !== "" &&
		submission.value.dateOfBirth !== ""
	);
});

function handleInput(key: string, val: string) {
	submission.value[key as keyof SignInOtpData] = val;
	validEmail.value = validateEmail(submission.value.email);
	switch (key) {
	case "givenName":
		validFirstName.value = useFormValidation().validateName(
			submission.value.givenName,
		);
		break;
	case "familyName":
		validLastName.value = useFormValidation().validateName(
			submission.value.familyName,
		);
		break;
	case "dateOfBirth":
		validateOfBirth.value = useFormValidation().validateDate(
			submission.value.dateOfBirth,
		);

		break;
	}
}
async function handleOtpSubmission() {
	authValid.value = true;
	if (validEmail.value) {
		if (validateOfBirth.value && dupEmail.value) {
			const dobDate = new Date(submission.value.dateOfBirth);
			const offset = dobDate.getTimezoneOffset();
			const isDobDate = new Date(dobDate.getTime() - offset * 60 * 1000);
			submission.value.dateOfBirth = isDobDate.toISOString().split("T")[0];
		}

		userStore.userObject.userEmail = submission.value.email;
		await userStore.otpLogin(submission.value);
		validSubmission.value = authValid.value;
	}
}
</script>
