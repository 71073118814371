<template>
  <div
    class="flex flex-col items-center bg-white card-container p-6 md:py-6 md:px-8"
  >
    <div class="flex flex-col w-full">
      <h1
        v-show="!dupEmail"
        class="text-m-title xl:text-26 mb-4 font-roboto font-semibold md:font-raleway md:font-bold"
      >
        Welcome to your insurance portal!
      </h1>
      <h1
        v-show="dupEmail"
        class="text-m-title xl:text-26 mb-4 font-roboto font-semibold md:font-raleway md:font-bold"
      >
        We need a little more information before we log you in...
      </h1>
      <h2
        v-show="!dupEmail"
        class="text-gray-400 text-m-normal xl:text-base xl:mb-6 mb-4 font-roboto"
      >
        Please enter your email and password to login.
      </h2>
      <h2
        v-show="dupEmail"
        class="text-gray-400 text-m-normal xl:text-base xl:mb-6 mb-4 font-roboto"
      >
        Please enter your first name, last name, and date of birth.
      </h2>
      <MolSignInForm />
      <div
        v-show="!dupEmail"
        class="hr-container"
      >
        <hr class="hr-line">
        <span class="hr-text">or</span>
        <hr class="hr-line">
      </div>
      <h2
        v-show="!dupEmail"
        class="text-gray-400 text-m-normal xl:text-base xl:mb-6 mb-4 font-roboto"
      >
        Request a one time pass-code to login to your account.
      </h2>
      <MolSignInOtpForm />
      <hr
        v-show="!dupEmail"
        class="bg-gray-200 mt-3 mb-4"
      >
      <p
        v-show="!dupEmail"
        class="font-bold text-m-small xl:text-xs"
      >
        Don’t have an account?
      </p>
      <p
        v-show="!dupEmail"
        class="text-m-small xl:text-xs mb-3"
      >
        Welcome to our new and improved Customer Portal! If this is your first
        time visiting, please set up your online profile today and you’ll have
        immediate access to policy information, Medicare Resources, your health
        profile and more.
      </p>
      <div
        v-show="!dupEmail"
        class="w-full text-center"
      >
        <NuxtLink
          to="/sign-up"
          class="text-blue-500 text-m-link xl:text-base underline xl:font-bold"
        >
          Create Your Account
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { useUserStore } from "~/store/user";
import { storeToRefs } from "pinia";

const userStore = useUserStore();
const { dupEmail } = storeToRefs(userStore);
</script>
<style scoped>
.card-container {
	@apply w-[356px] md:w-[356px] lg:w-[510px] rounded-[10px];
}
.hr-container {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 0.5rem 0;
}

.hr-line {
	flex-grow: 1;
	border: none;
	border-top: 1px solid #d1d5db; /* Tailwind's gray-300 */
	margin: 0 1rem;
}

.hr-text {
	font-size: 1rem;
	color: #6b7280; /* Tailwind's gray-500 */
}
</style>
